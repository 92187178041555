:root {
    --standard-border-radius: 1rem;
    --standard-seperation-distance: 12.5vh;
    --standard-x-padding: 8vh;

    --container-width: 90%;
    --container-max-width: 1500px;

    --large-screen-content-max-width: 1500px;

    --just-darker: #F6F9FC;
    /* 
        --just-darker: rgb(27, 117, 187, 0.025);
        --just-darker: #F6F9FC;
    */
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

body,
#root,
#App {
    /*min-height: 100%;*/
}


a.active {
    color: var(--ar-primary);
}

a.info-link {
    text-decoration: underline;
    color: var(--ar-gray-300);
}

a.info-link:hover {
    color: var(--ar-gray-500);
}

.grecaptcha-badge {
    visibility: hidden;
}

.below-navbar {
    /* Don't underlap the navbar. */
    margin-top: var(--navbar-height);
}

/* Containers */
.d4-container {
    width: var(--container-width);
    max-width: var(--container-max-width);
    margin-top: var(--standard-seperation-distance);
    margin-left: auto;
    margin-right: auto;
}


/* Overidden Around themes.*/

/* Buttons */

/* - Primary Button */

.btn-primary {
    border: none;
}

.btn-primary:hover {
    border: none;
}

.btn-primary:active {
    background-color: var(--ar-primary-hover) !important;
}

.btn-primary:disabled {
    background-color: var(--ar-primary-hover) !important;
}

.btn-outline-primary {
    border: var(--ar-btn-border-width) solid var(--ar-primary);
    color: var(--ar-primary);
}

.btn-outline-primary:disabled {
    border: var(--ar-btn-border-width) solid var(--ar-primary);
    color: var(--ar-primary);
}

.btn-outline-primary:hover {
    background-color: var(--ar-primary);
}

.btn-outline-primary:active {
    background-color: var(--ar-primary) !important;
}

/* - Secondary Button */
.btn-secondary:hover {
    background-color: var(--ar-link-secondary-hover-color);
}

/* Inputs */

input[type="text"],
input[type="email"],
textarea {
    color: #FFF !important;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: var(--ar-gray-600) !important;
}

input::placeholder,
textarea::placeholder {
    color: var(--ar-gray-400) !important;
}