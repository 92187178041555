#hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero .card-holder {
  max-width: 92%;
  max-height: 85%;
}

#hero .card-holder .card {
  background-color: var(--ar-secondary);
  border: none;
  padding: 2.5% 5%;
  border-radius: var(--standard-border-radius);
}

.react-parallax-bgimage {
  object-fit: cover;
}

@media (max-width: 768px) {
  #hero {
    height: 85vh;
  }
}

@media (min-width: 768px) {
  #hero {
    justify-content: flex-start;
    background-position-x: 0% !important;
  }

  #hero .card-holder {
    margin-left: 8%;
  }
  #hero .card-holder .card {
    max-width: 75%;
  }
}

@media (max-width: 768px) {
  #hero .card-holder .card .card-body .card-title {
    font-size: x-large;
  }
}

@media (min-width: 768px) {
  #hero {
    justify-content: flex-start;
    background-position-x: 0% !important;
  }
  #hero .card-holder {
    margin-left: 8%;
  }
  #hero .card-holder .card {
    max-width: 70%;
  }
  #hero .card-holder .card .card-body p {
    font-size: x-large;
  }
}
