.steps {
  max-width: 500px;
}

#steps-icons {
  display: flex;
  justify-content: center;
  text-align: left;
}

@media (max-width: 768px) {
  .step-body {
    font-size: small;
  }

  .roadmap-steps-and-text {
    padding: 5%;
  }
}

.progress-img {
  max-width: 60%;
  align-self: center;
  justify-content: space-between;
}

.card-body {
  justify-content: center;
}

@media (max-width: 1023px) {
  .progress-img {
    display: none;
  }

  .card-body {
    justify-content: center;
    width: 100%;
    padding: 0;
  }
}