:root {
  --navbar-height: 8vh;
  --hamburger-btn-bar-width: 40px;
  --hamburger-btn-bar-height: 2px;
  --hamburger-btn-bar-gap: 10px;
  --hamburger-btn-transition-time: 200ms ease-in-out;
  --hamburger-btn-height: calc(
    var(--hamburger-btn-bar-height) * 3 + var(--hamburger-btn-bar-gap) * 2
  );
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

nav {
  height: var(--navbar-height);
  padding: 0 5%;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  min-height: 60px;
}

nav > div {
  display: flex;
  align-items: center;
}

nav .logo {
  height: 55%;
}

#mobileNav {
  width: 100%;
  position: fixed;
  top: var(--navbar-height);
  left: 0;
  min-height: 40vh;
  height: 40vh;
  padding: 2%;
}

#mobileNav > ul {
  border: 2px solid var(--ar-border-color);
  border-radius: var(--standard-border-radius);
  height: 100%;
}

.nav-container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  nav {
    border-bottom: var(--ar-primary) solid 2px;
  }

  nav .nav-container {
    display: flex;
    flex-direction: column;
    padding: 5%;
    gap: 15px;
  }

  nav a {
    font-weight: bold;
    font-size: 28px;
  }

  nav a > i {
    display: none !important;
  }

  .custom-nav-link {
    font-size: 16px;
    font-weight: 500;
    margin-top: 9px;
    color: black;
    margin-left: 0 !important;
  }
}
