.feature-text {
  max-width: 500px;
}

.features-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.features-subtitle-text {
  max-width: 75%;
}

.feature-link {
  opacity: 1;
}

.feature-description {
  display: flex;
  flex-direction: column;
  text-align: justify;
}

@media (max-width: 1024px) {
  .card-hover .opacity-0 {
    opacity: 1 !important;
  }
}
