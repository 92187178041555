section.contact-container {
    display: flex;
    justify-content: center;
    margin-top: var(--standard-seperation-distance);
}

.contact-card {
    background-color: var(--ar-secondary);
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5% 5%;
    border-radius: var(--standard-border-radius);
    scroll-margin-top: calc(var(--navbar-height) + 5vh);
    margin-top: 0 !important;
}

.contact-card>h1 {
    color: white;
    margin-bottom: 4%;
}

.contact-card form {
    width: 70%;
}

@media (min-width: 768px) and (max-width: 992px) {
    .contact-card form {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .contact-card {
        width: 95%;
    }

    .contact-card form {
        width: 100%;
    }

    .contact-card form>*:not(.contact-card form > .form-group) {
        margin-top: 25px !important;
    }

    .contact-card form>.form-group>* {
        margin-top: 25px;
    }

    .contact-card {
        padding: 7.5% 5%;
    }
}

.slidecontainer {
    width: 100%;
    /* Width of the outside container */
}

/* The slider itself */
.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 25px;
    /* Specified height */
    background: #d3d3d3;
    /* Grey background */
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: #04AA6D;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: #04AA6D;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
}