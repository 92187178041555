.partners-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-partner {
  max-width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.partner-img {
  justify-content: center;
  width: 100%;
  height: 100%;
}

.partner-description {
  font-size: small;
}

@media (min-width: 768px) {
  .partners-outer-container {
    border-radius: var(--standard-border-radius);
  }

  .partners-container {
    flex-direction: row;
  }

  .single-partner {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .partner-img {
    justify-content: center;
    width: 50%;
    height: 50%;
  }
  .partner-description {
    width: 55%;
    font-size: medium;
    text-align: center;
  }
}
